import {useConfigProvider} from "../../../config"
import {
  Contact,
  JobFunction,
  OnboardingRequest,
  UpdateCRMContact,
} from "../../../types"
import {AccountTeam} from "../../../types/Account"

export const accountEndpoints = () => {
  const {
    token,
    userProfileServerUrl,
    userProfileApimKey,
    userProfileFaToken,
    accountServerUrl,
    faTokenAcct,
    apimKeyAcct,
  } = useConfigProvider()

  const headers = {
    "Content-Type": "application/json",
    "X-Functions-Key": faTokenAcct || "",
    "Ocp-Apim-Subscription-Key": apimKeyAcct || "",
    Authorization: `Bearer ${token}`,
  }

  const userHeaders = {
    "Content-Type": "application/json",
    "X-Functions-Key": userProfileFaToken || "",
    "Ocp-Apim-Subscription-Key": userProfileApimKey || "",
    Authorization: `Bearer ${token}`,
  }

  const acceptTermsAndConditions = async (emailAddress: string) => {
    const request = new Request(
      `${userProfileServerUrl}/acceptTermsAndConditions/${emailAddress}`,
      {
        headers: userHeaders,
        method: "GET",
      },
    )

    const response = await fetch(request)

    if (!response.ok) {
      throw new Error("Failed to accept terms and conditions")
    }

    return response.json() as Promise<boolean>
  }

  const completeOnboarding = async (onboardingRequest: OnboardingRequest) => {
    const request = new Request(`${userProfileServerUrl}/completeOnboarding`, {
      headers: userHeaders,
      method: "POST",
      body: JSON.stringify(onboardingRequest),
    })

    const response = await fetch(request)

    if (!response.ok) {
      throw new Error("Failed to complete onboarding")
    }

    return response.json() as Promise<boolean>
  }

  const completePreferences = async (emailAddress: string) => {
    const request = new Request(
      `${userProfileServerUrl}/completePreferences/${emailAddress}`,
      {
        headers: userHeaders,
        method: "POST",
      },
    )

    const response = await fetch(request)

    if (!response.ok) {
      throw new Error("Failed to complete preferences")
    }

    return response.json() as Promise<boolean>
  }

  const getJobFunctions = async () => {
    const request = new Request(`${userProfileServerUrl}/jobFunctions`, {
      headers: userHeaders,
    })

    const response = await fetch(request)

    if (!response.ok) {
      throw new Error("Failed to fetch job functions")
    }

    return response.json() as Promise<JobFunction[]>
  }

  const getProfileInfo = async (emailAddress: string) => {
    const request = new Request(
      `${userProfileServerUrl}/contact/${emailAddress}`,
      {
        headers: userHeaders,
        method: "GET",
      },
    )

    const response = await fetch(request)

    if (!response.ok) {
      throw new Error("Failed to fetch profile info")
    }

    return response.json() as Promise<Contact>
  }

  const updateContact = async (data: UpdateCRMContact) => {
    const request = new Request(`${userProfileServerUrl}/crm/update`, {
      headers: userHeaders,
      method: "PUT",
      body: JSON.stringify([data]),
    })

    const response = await fetch(request)

    if (!response.ok) {
      throw new Error("Failed to update contact")
    }

    return response.json() as Promise<boolean>
  }

  const getAccountTeamByAccountNumber = async (accountNumber: string) => {
    const request = new Request(
      `${accountServerUrl}/account/crm/${accountNumber}/team`,
      {
        headers,
      },
    )

    const response = await fetch(request)

    if (!response.ok) {
      throw new Error("Failed to fetch account team")
    }

    return response.json() as Promise<AccountTeam[]>
  }

  return {
    acceptTermsAndConditions,
    completeOnboarding,
    getJobFunctions,
    getProfileInfo,
    updateContact,
    getAccountTeamByAccountNumber,
    completePreferences,
  }
}
