import {type FC} from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  useTheme,
  Stack,
  IconButton,
} from "@mui/material"
import {Button} from "../../atoms"
import {CloseModalIcon} from "../../../../assets/icons"

type Props = {
  title: string
  message: string
  showDialog: boolean
  dialogProps?: DialogProps
  disabled?: boolean
  disabledClose?: boolean
  closeText?: string
  confirmText?: string
  closeIcon?: boolean
  handleClose: () => void
  handleConfirm: () => void
}

const ConfirmationDialog: FC<Props> = (props) => {
  const {
    title,
    message,
    showDialog,
    dialogProps,
    disabled,
    disabledClose,
    closeText = "Cancel",
    confirmText = "Confirm",
    handleClose,
    handleConfirm,
    closeIcon,
  } = props
  const theme = useTheme()
  return (
    // @ts-ignore
    <Dialog
      open={showDialog}
      onClose={handleClose}
      maxWidth="md"
      sx={{
        ".MuiModal-backdrop": {
          backgroundColor: "rgba(126, 128, 129, 0.3)",
        },
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "46.5rem",
          borderRadius: "0.5rem",
          paddingY: "1.5rem",
          backgroundColor: theme.palette.common.white,
          borderTopWidth: "7px",
          borderTopStyle: "solid",
          borderTopColor: "#053747",
        },
      }}
      {...dialogProps}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          paddingBottom: "1.5rem",
          paddingX: "2rem",
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "1.5rem",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            padding: "0",

            color: "#053747",
          }}
        >
          {title}
        </DialogTitle>
        {closeIcon && (
          <IconButton
            onClick={handleClose}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            sx={{padding: 0}}
          >
            <CloseModalIcon />
          </IconButton>
        )}
      </Stack>
      <DialogContent
        sx={{
          paddingY: "1rem !important",
          paddingX: "2rem",
          borderTop: "1px solid #CACDCD",
          fontSize: "1rem",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "1.5rem",
          color: "#242D35",
        }}
      >
        {message}
      </DialogContent>
      <DialogActions
        sx={{justifyContent: "flex-end", gap: "1.5rem", paddingX: "2rem"}}
      >
        <Button
          disabled={disabledClose}
          sx={{
            height: "40px",
            textTransform: "none",
            textDecoration: "underline",
            textUnderlineOffset: "0.5rem",
            border: "none",
            backgroundColor: "white",
            color: "#053747",
            "&:hover": {
              backgroundColor: "white",
              color: "#053747",
              textDecoration: "underline",
            },
          }}
          onClick={handleClose}
        >
          {closeText}
        </Button>
        <Button
          disabled={disabled}
          onClick={handleConfirm}
          sx={{
            minWidth: "150px",
            height: "40px",
            textTransform: "none",
            backgroundColor: "#053747",
            color: "white",
            "&:hover": {
              backgroundColor: "#053747",
              color: "white",
            },
          }}
          color="primary"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
