import {Divider, Link, Popover, Stack, Typography} from "@mui/material"
import {FC, useState} from "react"
import {useSessionStorage} from "usehooks-ts"
import {CheckIcon, LanguageIcon} from "../../../../assets/icons"
import {useConfigProvider} from "../../../../config"
import {NewButton} from "../../atoms"
import {useTranslation} from "react-i18next"

const LanguageDropdown: FC = () => {
  // const [language, setLanguage] = useLocalStorage("language", "en")
  const [language, setLanguage] = useSessionStorage("language", "en")
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const open = Boolean(anchorEl)
  const {t} = useTranslation()
  const {languages} = useConfigProvider()

  if (!languages || languages.length === 0) return null
  return (
    <Stack height={"100%"} justifyContent={"center"}>
      <NewButton
        text={languages
          .find((lang) => lang.code === language)
          ?.code.replace(/_.*/, "")
          .toUpperCase()}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        variant="text"
        size="medium"
        color="primary"
        IconLeft={<LanguageIcon />}
        disableFocusRipple
        disableRipple
        sx={{
          fontWeight: 400,
          fontSize: "12px",
          gap: 0,
          marginLeft: "auto",
          paddingY: "4px",
          "&:hover": {bgcolor: "unset"},
          "&:active": {bgcolor: "unset"},
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            bgcolor: "white",
            width: "202px",
            boxShadow: "0px 0px 2px 0px #0000001F, 0px 4px 8px 0px #00000024",
            border: "1px solid #EEEEEE",
            display: "flex",
            flexDirection: "column",
          },
        }}
        slotProps={{
          paper: {
            role: "listbox",
            "aria-modal": true,
            "aria-label": "country-select",
          },
        }}
      >
        <Typography
          py={"7.5px"}
          px={"16px"}
          color={"#595958"}
          fontSize={"12px"}
        >
          {t("Select session language")}
        </Typography>
        {languages.map((lang) => (
          <NewButton
            key={lang.code}
            text={lang.name}
            onClick={() => {
              setAnchorEl(null)
              setLanguage(lang.code)
            }}
            IconRight={lang.code === language && <CheckIcon />}
            variant="text"
            size="medium"
            color="secondary"
            fullWidth
            sx={{
              fontSize: "14px",
              justifyContent: "space-between",
              color: "#242d35",
              fontWeight: lang.code === language ? 600 : 400,
            }}
          />
        ))}
        <Divider />
        <Typography
          py={"6.5px"}
          px={"14px"}
          color={"#595958"}
          fontSize={"12px"}
        >
          {t("This is a temporary change. To set the default language, visit")}{" "}
          <Link href="/profile" color={"#226CA6"}>
            {t("account preferences")}
          </Link>
          .
        </Typography>
      </Popover>
    </Stack>
  )
}

export default LanguageDropdown
