export const langs = [
  {
    label: "English",
    value: "315910000",
    code: "en",
  },
  {
    label: "Deutsch",
    value: "315910002",
    code: "de",
  },
  {
    label: "Español",
    value: "315910003",
    code: "es",
  },
  {
    label: "Français",
    value: "315910001",
    code: "fr",
  },
  {
    label: "日本語",
    value: "315910006",
    code: "ja",
  },
  {
    label: "Português",
    value: "315910004",
    code: "pt_BR",
  },
]

export const geographyOfInterest = [
  {
    label: "Australia, New Zealand and the Pacific",
    value: "cr_goi_australianewzealandandthepacific",
  },
  {label: "Benelux", value: "cr_goi_benelux"},
  {
    label: "DACH, Central & Eastern Europe",
    value: "cr_goi_dachcentraleasterneurope",
  },
  {label: "East Africa", value: "cr_goi_eastafrica"},
  {label: "Greater China", value: "cr_goi_greaterchina"},
  {label: "India and South Asia", value: "cr_goi_indiaandsouthasia"},
  {label: "Latin America & Caribbean", value: "cr_goi_latinamericacaribbean"},
  {label: "Middle East & North Africa", value: "cr_goi_middleeastnorthafrica"},
  {label: "Nordics", value: "cr_goi_nordics"},
  {label: "North Asia", value: "cr_goi_northasia"},
  {label: "Southeast Asia", value: "cr_goi_southeastasia"},
  {label: "Southern Europe", value: "cr_goi_southerneurope"},
  {label: "Sub-Saharan Africa", value: "cr_goi_subsaharanafrica"},
  {label: "UK & Ireland", value: "cr_goi_ukireland"},
  {label: "US & Canada", value: "cr_goi_uscanada"},
  {label: "West Africa", value: "cr_goi_westafrica"},
]

export const areasOfInterest = [
  {label: "Compliance and governance", value: "cr_aoi_complianceandgovernance"},
  {label: "Cyber and digital risk", value: "cr_aoi_cyberanddigitalrisk"},
  {label: "Investment support", value: "cr_aoi_investmentsupport"},
  {label: "Legal", value: "cr_aoi_legal"},
  {
    label: "Political and country risk",
    value: "cr_aoi_politicalandcountryrisk",
  },
  {label: "Security risk", value: "cr_aoi_securityrisk"},
]
