import {yupResolver} from "@hookform/resolvers/yup"
import {
  Box,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  InputBaseProps,
  MenuItem,
  Modal,
  Popover,
  Select,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material"
import {useMutation, useQuery} from "@tanstack/react-query"
import {
  Fragment,
  MouseEvent,
  PropsWithChildren,
  useEffect,
  useState,
  type FC,
} from "react"
import {Controller, useForm, UseFormTrigger} from "react-hook-form"
import {decodeToken} from "react-jwt"
import {
  getCountries,
  getCountryCallingCode,
  isPossiblePhoneNumber,
  isSupportedCountry,
} from "react-phone-number-input/input"
import countryLabels from "react-phone-number-input/locale/en.json"
import PhoneInput from "react-phone-number-input/react-hook-form"
import "react-phone-number-input/style.css"
import * as yup from "yup"
import {
  ChevronDownIcon,
  CloseIcon,
  InfoIcon,
  ReportProblemIcon,
  SuccessIcon,
} from "../../../../assets/icons"
import {useConfigProvider} from "../../../../config"
import {stringIsLink, stringIsNotSQL, stringIsScript} from "../../../../helpers"
import {StorageData} from "../../../../hooks/useStorage"
import {accountEndpoints, fabricEndpoints} from "../../../../services"
import {
  FabricEvent,
  OnboardingRequest,
  Token,
  UpdateCRMContact,
} from "../../../../types"
import {NewButton, Tooltip} from "../../atoms"
import {ConfirmationDialog, SearchBar, TextWithTooltip} from "../../molecules"
import {useSessionStorage} from "usehooks-ts"
import {appInsights} from "../../../../config/appInsights"
import {toast} from "react-toastify"
import {useTranslation} from "react-i18next"
import {areasOfInterest, geographyOfInterest, langs} from "./constants"
import {CR_ACCOUNT_NUMBER} from "../../../../constants"
// import {useExpiringLocalStorage} from "../../../../hooks/useExpiringLocalStorage"

const countryCodes = getCountries()

const countries = countryCodes
  .map((countryCode) => ({
    label: countryLabels[countryCode],
    value: countryCode,
    callingCode: getCountryCallingCode(countryCode),
  }))
  .sort((a, b) => a.label.localeCompare(b.label))

const schema = yup.object({
  firstName: yup
    .string()
    .required("Required field")
    .min(2, "First name is not valid")
    .max(50, "Character limit reached")
    .matches(stringIsLink, "First name is not valid")
    .matches(stringIsNotSQL, "First name is not valid")
    .matches(stringIsScript, "First name is not valid"),
  lastName: yup
    .string()
    .required("Required field")
    .min(2, "Last name is not valid")
    .max(50, "Character limit reached")
    .matches(stringIsLink, "Last name is not valid")
    .matches(stringIsNotSQL, "Last name is not valid")
    .matches(stringIsScript, "Last name is not valid"),
  jobTitle: yup
    .string()
    .required("Required field")
    .min(2, "Job title is not valid")
    .max(204, "Character limit reached")
    .matches(stringIsLink, "Job title is not valid")
    .matches(stringIsNotSQL, "Job title is not valid")
    .matches(stringIsScript, "Job title is not valid"),
  jobFunction: yup.string().required("Required field"),
  workPhoneNumber: yup
    .string()
    .required("Required field")
    .test("phone-number", "Phone number is not valid", (value) => {
      if (!value) return false
      return isPossiblePhoneNumber(value)
    })
    .max(50, "Character limit reached"),
  preferredLanguage: yup.string(),
})

type FormValues = yup.InferType<typeof schema>

const preferencesSchema = yup.object().shape({
  selectedGeographies: yup.array().of(yup.string()).notRequired(),
  selectedAreas: yup.array().of(yup.string()).notRequired(),
})

type PreferencesFormValues = yup.InferType<typeof preferencesSchema>

const OnboardingModal: FC = () => {
  const {
    getJobFunctions,
    getProfileInfo,
    completeOnboarding,
    updateContact,
    completePreferences,
  } = accountEndpoints()
  const {sendFabricEvents} = fabricEndpoints()

  const theme = useTheme()
  const {token} = useConfigProvider()
  const {t} = useTranslation()

  const decoded = decodeToken<Token>(token)
  const email = decoded?.email || ""
  const [accepted] = useSessionStorage<boolean | undefined>(
    StorageData.acceptedTermsAndConditions,
    undefined,
  )
  const [completed, setCompleted] = useSessionStorage<boolean | undefined>(
    StorageData.completedContactForm,
    undefined,
  )
  const [_, setPreferencesCompleted] = useSessionStorage<boolean | undefined>(
    StorageData.completedPreferences,
    undefined,
  )
  const [___, setPreferencesSkipped] = useSessionStorage<boolean | undefined>(
    StorageData.skippedPreferences,
    undefined,
  )
  const [step, setStep] = useState(0)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [showSkipPreferencesDialog, setShowSkipPreferencesDialog] =
    useState(false)

  const acceptedTermsAndConditions =
    !!decoded?.acceptedTermsAndConditions || !!accepted || false
  const completedContactForm =
    !!decoded?.completedContactForm || !!completed || false
  const [open, setOpen] = useState<boolean>(
    acceptedTermsAndConditions && !completedContactForm,
  )

  const [__, setLanguage] = useSessionStorage("language", "en")
  // const [___, setTempLanguage] = useExpiringLocalStorage<string>(
  //   "tempLanguage",
  //   "",
  //   10 * 60 * 1000, //expire in 10 minutes
  // )

  const getProfileInfoQuery = useQuery({
    queryKey: ["profileInfo", email],
    queryFn: () => getProfileInfo(email!!),
    enabled: !!email && open,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const canCompleteForm =
    !!getProfileInfoQuery.data?.contactId &&
    !email.includes("@controlrisks.com") &&
    decoded?.companyId !== CR_ACCOUNT_NUMBER

  const getJobFunctionsQuery = useQuery({
    queryKey: ["jobFunctions"],
    queryFn: () => getJobFunctions(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: open,
  })

  const {reset, control, handleSubmit, trigger, watch} = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      preferredLanguage: langs[0]?.value,
    },
  })

  const {
    control: preferencesControl,
    handleSubmit: preferencesHandleSubmit,
    watch: preferencesWatch,
    reset: preferencesReset,
  } = useForm<PreferencesFormValues>({
    defaultValues: {
      selectedGeographies: [],
      selectedAreas: [],
    },
    resolver: yupResolver(preferencesSchema),
  })

  const contactCardValues = watch()
  const preferencesValues = preferencesWatch()

  useEffect(() => {
    if (!getProfileInfoQuery.isLoading && getProfileInfoQuery.data?.contactId) {
      reset({
        firstName: getProfileInfoQuery.data?.firstName || undefined,
        lastName: getProfileInfoQuery.data?.lastName || undefined,
        jobTitle: getProfileInfoQuery.data?.jobTitle || undefined,
        jobFunction: getProfileInfoQuery.data?.jobFunction || undefined,
        workPhoneNumber: getProfileInfoQuery.data?.businessPhone || undefined,
        preferredLanguage:
          getProfileInfoQuery.data?.preferredLanguage?.toString() || undefined,
      })
      preferencesReset({
        selectedAreas:
          getProfileInfoQuery.data?.marketingAreas?.map((item) =>
            item.toLowerCase(),
          ) || undefined,
        selectedGeographies:
          getProfileInfoQuery.data?.marketingGeographies?.map((item) =>
            item.toLowerCase(),
          ) || undefined,
      })
      if (canCompleteForm) {
        trigger()
      }
    } else if (
      email.includes("@controlrisks.com") ||
      decoded?.companyId === CR_ACCOUNT_NUMBER
    ) {
      reset({
        firstName: decoded?.firstname || undefined,
        lastName: decoded?.surname || undefined,
        jobTitle: decoded?.jobTitle || undefined,
        jobFunction: decoded?.department || undefined,
      })
    }
  }, [getProfileInfoQuery.isLoading, getProfileInfoQuery.data?.contactId])

  const selectedLanguage = watch("preferredLanguage")

  useEffect(() => {
    if (selectedLanguage) {
      setLanguage(
        langs.find((lang) => lang.value === selectedLanguage)?.code || "en",
      )
      // setTempLanguage(
      //   langs.find((lang) => lang.value === selectedLanguage)?.code || "en",
      // )
    }
  }, [selectedLanguage])

  const sendFabricEventMutation = useMutation({
    mutationFn: sendFabricEvents,
    mutationKey: ["sendFabricEvent"],
  })

  const completeOnboardingMutation = useMutation<
    boolean,
    Error,
    OnboardingRequest
  >({
    mutationFn: (data) => completeOnboarding(data),
    onSuccess: async (res, data) => {
      setCompleted(true)
      if (step === 1) {
        setOpen(false)
      } else {
        setStep(1)
      }

      toast.success("Your contact card has been saved", {
        autoClose: 3000,
        style: {
          borderLeftColor: "#58A733",
        },
        icon: SuccessIcon,
      })

      appInsights.trackEvent({
        name: "ONBOARDING",
        properties: {
          userId: decoded?.sub,
          userName: `${decoded?.firstname} ${decoded?.surname}`,
          email: decoded?.email,
          companyID: decoded?.companyId,
          payload: {
            ...data,
          },
        },
      })
      const fabricEvent = {
        type: "Update_contact",
        targetId: data.contactId || decoded?.sub || "",
        targetName: `${data.firstName} ${data.lastName}`,
        appUrl: window.location.href,
      } satisfies FabricEvent

      await sendFabricEventMutation.mutateAsync([fabricEvent])
    },
  })

  const completePreferencesMutation = useMutation<boolean, Error, string>({
    mutationFn: (data) => completePreferences(data),
  })

  const updateCRMContactMutation = useMutation({
    mutationFn: updateContact,
    mutationKey: ["updateCRMContact"],
  })

  const sendFabricEventsMutation = useMutation({
    mutationFn: sendFabricEvents,
    mutationKey: ["sendFabricEvents"],
    throwOnError: false,
  })

  const handleClose = () => {
    if (!completedContactForm) {
      const event = new CustomEvent("logout")
      window.dispatchEvent(event)
      return
    }
    setOpen(false)
  }

  const handleClosePreferences = () => {
    if (
      (preferencesValues.selectedAreas?.length ?? 0) > 0 ||
      (preferencesValues.selectedGeographies?.length ?? 0) > 0
    ) {
      setShowSkipPreferencesDialog(true)
    } else {
      setOpen(false)
      setPreferencesSkipped(true)
    }
  }

  const handleSubmitContactCard = async (data: FormValues) => {
    if (completedContactForm || !decoded?.email || !getProfileInfoQuery.data) {
      const payload = {
        email: decoded?.email!,
        firstName: decoded?.firstname || "",
        lastName: decoded?.surname || "",
        jobTitle: decoded?.jobTitle || "",
        jobFunction: decoded?.department || "other",
        phone: "",
        contactId: "",
      } satisfies OnboardingRequest

      await completeOnboardingMutation.mutateAsync(payload)
      return
    }
    const payload = {
      email: decoded?.email,
      firstName: data.firstName,
      lastName: data.lastName,
      jobTitle: data.jobTitle,
      jobFunction: data.jobFunction,
      phone: data.workPhoneNumber,
      contactId: getProfileInfoQuery.data.contactId,
    } satisfies OnboardingRequest
    await completeOnboardingMutation.mutateAsync(payload)

    const updatePayload = {
      _cr_accountname_value:
        getProfileInfoQuery.data.accountId || decoded?.companyId,
      contactid: getProfileInfoQuery.data.contactId,
      emailaddress1: decoded?.email,
      firstname: data.firstName,
      lastname: data.lastName,
      telephone1: data.workPhoneNumber,
      Jobtitle: data.jobTitle,
      new_jobfunction: data.jobFunction,
      cr_language: data.preferredLanguage,
    } satisfies UpdateCRMContact

    await updateCRMContactMutation.mutateAsync(updatePayload)
  }

  const handleSubmitInternal = async () => {
    const payload = {
      email: decoded?.email || "",
      firstName: decoded?.firstname || "",
      lastName: decoded?.surname || "",
      jobTitle: decoded?.jobTitle || "",
      jobFunction: decoded?.department || "other",
      phone: "",
      contactId: decoded?.contactId || "",
    } satisfies OnboardingRequest

    await completeOnboardingMutation.mutateAsync(payload)
    return
  }
  const handleSubmitPreferences = async (data: PreferencesFormValues) => {
    if (getProfileInfoQuery.data?.accountId || decoded?.companyId) {
      const selectedPreferences = [
        ...(data.selectedGeographies ?? []).filter(
          (item): item is string => item !== undefined,
        ),
        ...(data.selectedAreas ?? []).filter(
          (item): item is string => item !== undefined,
        ),
      ]
      const updatePayload = {
        _cr_accountname_value:
          getProfileInfoQuery?.data?.accountId || decoded?.companyId || "",
        contactid: getProfileInfoQuery.data?.contactId || "",
        emailaddress1: decoded?.email || "",
        firstname: contactCardValues.firstName,
        lastname: contactCardValues.lastName,
        telephone1: contactCardValues.workPhoneNumber,
        Jobtitle: contactCardValues.jobTitle,
        new_jobfunction: contactCardValues.jobFunction,
        cr_language: contactCardValues.preferredLanguage,
        marketingAreas: areasOfInterest.map((area) => [
          area.value,
          selectedPreferences?.includes(area.value) ? "1" : "0",
        ]),
        marketingGeographies: geographyOfInterest.map((geography) => [
          geography.value,
          selectedPreferences?.includes(geography.value) ? "1" : "0",
        ]),
      } satisfies UpdateCRMContact

      await updateCRMContactMutation.mutateAsync(updatePayload)
      if (updateCRMContactMutation.isSuccess) {
        appInsights.trackEvent({
          name: "MarketingPreferences",
          properties: {
            type: "MarketingPreferences",
            targetId: getProfileInfoQuery.data?.contactId || "",
            targetName: `${contactCardValues.firstName} ${contactCardValues.lastName}`,
            appUrl: window.location.href,
            userId: decoded?.contactId,
            signinidentifier: decoded?.email,
            userdisplayname: decoded?.name,
            company: getProfileInfoQuery.data?.accountNumber,
            groups: decoded?.groups,
          },
        })
        await sendFabricEventsMutation.mutateAsync([
          {
            type: "MarketingPreferences",
            targetId: getProfileInfoQuery.data?.contactId || "",
            targetName: `${contactCardValues.firstName} ${contactCardValues.lastName}`,
            appUrl: window.location.href,
          },
        ])
        await completePreferencesMutation.mutateAsync(decoded?.email!)
        toast.success("Your marketing preferences have been saved", {
          autoClose: 3000,
          style: {
            borderLeftColor: "#58A733",
          },
          icon: SuccessIcon,
        })
        setPreferencesCompleted(true)
        setOpen(false)
      }
    }
  }

  const goiColumns = splitIntoVerticalColumns(geographyOfInterest, 2)
  const aoiColumns = splitIntoVerticalColumns(areasOfInterest, 2)

  useEffect(() => {
    if (acceptedTermsAndConditions && !completedContactForm) {
      setOpen(true)
    }
  }, [acceptedTermsAndConditions])

  return (
    <Modal
      open={open}
      aria-labelledby="onboarding-modal"
      aria-describedby="onboarding"
      sx={{
        "&.MuiModal-root": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          outline: "none",
        },
      }}
    >
      {getProfileInfoQuery.isLoading ? (
        <Stack
          width="704px"
          height="481px"
          bgcolor="white"
          justifyContent="center"
          alignItems="center"
        >
          <Stack>
            <CircularProgress />
          </Stack>
        </Stack>
      ) : (
        <Stack bgcolor={"#fff"}>
          <Stack
            borderTop={`7px solid ${theme.palette.primary.main}`}
            component="header"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              fontSize="20px"
              fontWeight={600}
              color="#242d35"
              lineHeight="1.2"
              paddingX="32px"
              paddingTop="17px"
              paddingBottom="20px"
            >
              {t("Welcome to MyControlRisks")}
            </Typography>
            <NewButton
              icon
              disableRipple
              variant="text"
              onClick={step === 1 ? handleClosePreferences : handleClose}
              IconLeft={<CloseIcon />}
              sx={{
                padding: "0",
                minWidth: "auto",
                height: "auto",
                width: "auto",
                marginRight: "32px",

                svg: {
                  width: "24px",
                  height: "24px",
                },
              }}
            />
          </Stack>
          <Stack direction={"row"}>
            <Stack>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={step}
                aria-label="Vertical tabs example"
                sx={{
                  "& .MuiTabs-indicator": {
                    right: "unset",
                    bgcolor: "#226ca5",
                    width: "4px",
                  },
                  "& .Mui-selected": {
                    color: "#226ca6 !important",
                  },
                  minWidth: "185px",
                  borderRadius: 0,
                }}
              >
                <Tab
                  label={
                    <Typography>
                      {t("Contact Card")}{" "}
                      <span style={{color: "#db0d00"}}>*</span>
                    </Typography>
                  }
                  disableTouchRipple
                  {...TabsProps(0, step)}
                />
                <Tab
                  label={t("Preferences")}
                  disableTouchRipple
                  {...TabsProps(1, step)}
                />
              </Tabs>
            </Stack>
            <Stack borderTop="1px solid #dedede">
              <TabPanel value={step} index={0}>
                <Stack
                  component="form"
                  width="766px"
                  bgcolor="white"
                  gap="16px"
                  overflow="hidden"
                  sx={{outline: "none"}}
                  paddingBottom="24px"
                  onSubmit={handleSubmit(handleSubmitContactCard)}
                >
                  <Stack
                    component="section"
                    overflow="auto"
                    gap="16px"
                    borderLeft={"1px solid #dedede"}
                    height={"487px"}
                    paddingX="32px"
                    pt={"24px"}
                  >
                    <Typography
                      component="p"
                      fontSize="14px"
                      fontWeight={400}
                      color="#242d35"
                      lineHeight="1.43"
                      textAlign="left"
                    >
                      {t(
                        "Please complete your profile below to access the platform. Note that if you cancel, you will exit MyControlRisks.",
                      )}
                    </Typography>
                    <Typography
                      component="span"
                      fontSize="14px"
                      fontWeight={400}
                      color="#7a7a79"
                      lineHeight="1.43"
                      textAlign="left"
                    >
                      {t("All fields are mandatory.")}
                    </Typography>
                    <Stack
                      component="section"
                      flexDirection="column"
                      gap="16px"
                    >
                      <Stack
                        flexDirection="row"
                        gap="16px"
                        component="section"
                        alignItems="flex-start"
                      >
                        <Controller
                          control={control}
                          name="firstName"
                          render={({field, fieldState}) => (
                            <FormGroup>
                              <InputLabel
                                htmlFor="firstName"
                                error={!!fieldState.error}
                              >
                                {t("First name")}
                              </InputLabel>
                              <Input
                                {...field}
                                id="firstName"
                                aria-label="First name"
                                placeholder="First name"
                                disabled={
                                  !getProfileInfoQuery.data || !canCompleteForm
                                }
                                error={!!fieldState.error}
                                sx={{
                                  width: "100%",
                                  input: {
                                    paddingX: "16px",
                                    paddingY: "6px",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    borderWidth: "1px",
                                    borderStyle: "solid",
                                    borderColor: fieldState.error
                                      ? "#DB0D00"
                                      : "#BDBDBC",
                                    borderRadius: "4px",
                                    color: "#242d35",
                                    height: "32px",
                                    boxSizing: "border-box",
                                    transition: "border-color 0.2s ease-in-out",

                                    "::placeholder": {
                                      color: "#7A7A79",
                                    },
                                  },
                                }}
                              />
                              <ErrorMessage>
                                {fieldState.error
                                  ? fieldState.error.message
                                  : null}
                              </ErrorMessage>
                            </FormGroup>
                          )}
                        />
                        <Controller
                          control={control}
                          name="lastName"
                          render={({field, fieldState}) => (
                            <FormGroup>
                              <InputLabel
                                htmlFor="lastName"
                                error={!!fieldState.error}
                              >
                                {t("Last name")}
                              </InputLabel>
                              <Input
                                {...field}
                                id="lastName"
                                aria-label="Last name"
                                placeholder="Last name"
                                disabled={
                                  !getProfileInfoQuery.data || !canCompleteForm
                                }
                                error={!!fieldState.error}
                                sx={{
                                  width: "100%",
                                  input: {
                                    paddingX: "16px",
                                    paddingY: "6px",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    borderWidth: "1px",
                                    borderStyle: "solid",
                                    borderColor: fieldState.error
                                      ? "#DB0D00"
                                      : "#BDBDBC",
                                    borderRadius: "4px",
                                    color: "#242d35",
                                    height: "32px",
                                    boxSizing: "border-box",
                                    transition: "border-color 0.2s ease-in-out",

                                    "::placeholder": {
                                      color: "#7A7A79",
                                    },
                                  },
                                }}
                              />
                              <ErrorMessage>
                                {fieldState.error
                                  ? fieldState.error.message
                                  : null}
                              </ErrorMessage>
                            </FormGroup>
                          )}
                        />
                      </Stack>
                      <Stack
                        flexDirection="row"
                        gap="16px"
                        component="section"
                        alignItems="flex-start"
                      >
                        <Controller
                          control={control}
                          name="jobTitle"
                          render={({field, fieldState}) => (
                            <FormGroup>
                              <InputLabel
                                htmlFor="jobTitle"
                                error={!!fieldState.error}
                              >
                                {t("Job title")}
                              </InputLabel>
                              <Input
                                {...field}
                                id="jobTitle"
                                aria-label="Job title"
                                placeholder="Job title"
                                disabled={
                                  !getProfileInfoQuery.data || !canCompleteForm
                                }
                                error={!!fieldState.error}
                                sx={{
                                  width: "100%",
                                  input: {
                                    paddingX: "16px",
                                    paddingY: "6px",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    borderWidth: "1px",
                                    borderStyle: "solid",
                                    borderColor: fieldState.error
                                      ? "#DB0D00"
                                      : "#BDBDBC",
                                    borderRadius: "4px",
                                    color: "#242d35",
                                    height: "32px",
                                    boxSizing: "border-box",
                                    transition: "border-color 0.2s ease-in-out",

                                    "::placeholder": {
                                      color: "#7A7A79",
                                    },
                                  },
                                }}
                              />
                              <ErrorMessage>
                                {fieldState.error
                                  ? fieldState.error.message
                                  : null}
                              </ErrorMessage>
                            </FormGroup>
                          )}
                        />
                        <Controller
                          control={control}
                          name="jobFunction"
                          render={({field, fieldState}) => (
                            <FormGroup>
                              <InputLabel
                                htmlFor="jobFunction"
                                error={!!fieldState.error}
                              >
                                {t("Job function")}
                              </InputLabel>
                              <Select
                                {...field}
                                IconComponent={ChevronDownIcon}
                                disabled={
                                  !getProfileInfoQuery.data || !canCompleteForm
                                }
                                placeholder={t("Select job function")}
                                sx={{
                                  ".MuiSelect-icon": {
                                    right: "20px",
                                  },
                                  ".MuiInputBase-input": {
                                    paddingTop: "3px",
                                    paddingBottom: "4px",
                                    paddingLeft: "16px",
                                    borderRadius: "4px",
                                    transition: "all 0.2s ease-in-out",
                                  },
                                  ".MuiOutlinedInput-input": {
                                    borderStyle: "solid",
                                    borderWidth: "1px",
                                    borderColor: fieldState.error
                                      ? "#DB0D00"
                                      : "#BDBDBC",
                                  },
                                  ".Mui-disabled": {
                                    color: "#242d35",
                                    "-webkit-text-fill-color": "#242d35",
                                    opacity: 1,
                                    backgroundColor: "#BDBDBC",

                                    "::placeholder": {
                                      color: "#7A7A79",
                                      "-webkit-text-fill-color": "#7A7A79",
                                    },
                                  },
                                  fieldset: {
                                    border: "none",
                                  },
                                }}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  sx: {
                                    ".MuiPaper-root": {
                                      boxShadow:
                                        "0px 0px 2px 0px #0000001F, 0px 4px 8px 0px #00000024",
                                      border: "1px solid #EEEEEE",
                                      padding: "0",
                                      backgroundColor: "#FFFFFF",
                                    },
                                  },
                                  slotProps: {
                                    paper: {
                                      sx: {
                                        maxHeight: "160px",
                                      },
                                    },
                                  },
                                }}
                                displayEmpty
                                renderValue={(value) => {
                                  const hasValue =
                                    !!value ||
                                    !!getProfileInfoQuery.data?.jobFunction
                                  if (!hasValue)
                                    return (
                                      <Typography
                                        component="span"
                                        fontSize="14px"
                                        fontWeight={400}
                                        color="#7A7A79"
                                        lineHeight="1.43"
                                        textAlign="left"
                                        sx={{
                                          "-webkit-text-fill-color": "#7A7A79",
                                        }}
                                      >
                                        Select job function
                                      </Typography>
                                    )
                                  return (
                                    <Typography
                                      component="span"
                                      fontSize="14px"
                                      fontWeight={400}
                                      color="#242d35"
                                      lineHeight="1.43"
                                      textAlign="left"
                                      sx={{
                                        "-webkit-text-fill-color": "#242d35",
                                      }}
                                    >
                                      {value ||
                                        getProfileInfoQuery.data?.jobFunction}
                                    </Typography>
                                  )
                                }}
                              >
                                {getJobFunctionsQuery.data?.map(
                                  (jobFunction) => (
                                    <MenuItem
                                      key={jobFunction.id}
                                      value={jobFunction.jobFunction}
                                      sx={{
                                        fontSize: "14px",
                                        padding: "8px 16px",
                                        cursor: "pointer",
                                        transition:
                                          "background-color 0.2s ease-in-out",
                                        backgroundColor:
                                          field.value ===
                                          jobFunction.jobFunction
                                            ? "#DCEBF3"
                                            : "#FFFFFF",
                                        ":hover": {
                                          backgroundColor:
                                            field.value ===
                                            jobFunction.jobFunction
                                              ? "#DCEBF3"
                                              : "#F5F5F5",
                                        },
                                      }}
                                      disableRipple
                                    >
                                      <Typography
                                        component="span"
                                        fontSize="14px"
                                        fontWeight={400}
                                        color="#242d35"
                                        lineHeight="1.43"
                                        textAlign="left"
                                      >
                                        {jobFunction.jobFunction}
                                      </Typography>
                                    </MenuItem>
                                  ),
                                )}
                              </Select>
                              <ErrorMessage>
                                {fieldState.error
                                  ? fieldState.error.message
                                  : null}
                              </ErrorMessage>
                            </FormGroup>
                          )}
                        />
                      </Stack>
                      <Stack
                        flexDirection="row"
                        gap="16px"
                        component="section"
                        alignItems="flex-start"
                      >
                        <FormGroup>
                          <InputLabel>{t("Email address")}</InputLabel>
                          <Stack
                            height="36px"
                            paddingTop="8px"
                            paddingBottom="4px"
                          >
                            <Typography
                              aria-label="email-address"
                              component="span"
                              fontSize="14px"
                              fontWeight={400}
                              color="#242d35"
                              lineHeight="1.43"
                              textAlign="left"
                            >
                              {email}
                            </Typography>
                          </Stack>
                        </FormGroup>
                        <Controller
                          control={control}
                          name="workPhoneNumber"
                          render={({field, fieldState}) => (
                            <FormGroup
                              sx={{
                                ".PhoneInput": {
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "8px",
                                },
                                input: {
                                  padding: "8px 16px",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderColor: fieldState.error
                                    ? "#DB0D00"
                                    : "#BDBDBC",
                                  borderRadius: "4px",
                                  color: "#242d35",
                                  height: "32px",
                                  boxSizing: "border-box",
                                  transition: "border-color 0.2s ease-in-out",
                                  outline: "none",
                                  fontFamily:
                                    "'Open Sans', Arial, Helvetica, sans-serif !important",

                                  "::placeholder": {
                                    color: "#7A7A79 !important",
                                  },

                                  "&:disabled": {
                                    backgroundColor: "#BDBDBC",
                                    borderColor: "#BDBDBC",
                                    color: "#242d35",
                                  },
                                },
                              }}
                            >
                              <InputLabel
                                htmlFor="workPhoneNumber"
                                error={!!fieldState.error}
                              >
                                {t("Work phone number")}
                              </InputLabel>
                              <PhoneInput
                                control={control}
                                name="workPhoneNumber"
                                placeholder={t("Work phone number")}
                                disabled={
                                  !getProfileInfoQuery.data || !canCompleteForm
                                }
                                defaultCountry="GB"
                                countrySelectComponent={CountrySelect}
                                countrySelectProps={{
                                  error: !!fieldState.error,
                                  disabled:
                                    !getProfileInfoQuery.data ||
                                    !canCompleteForm,
                                  trigger: trigger,
                                }}
                                flagUrl="/flags/{XX}.svg"
                                focusInputOnCountrySelection={true}
                              />
                              <ErrorMessage>
                                {fieldState.error
                                  ? fieldState.error.message
                                  : null}
                              </ErrorMessage>
                            </FormGroup>
                          )}
                        />
                      </Stack>
                      <Stack
                        flexDirection="row"
                        gap="16px"
                        component="section"
                        alignItems="flex-start"
                        width={"50%"}
                        pr="8px"
                      >
                        <Controller
                          control={control}
                          name="preferredLanguage"
                          render={({field, fieldState}) => (
                            <FormGroup>
                              <Stack direction={"row"} gap={"8px"}>
                                <InputLabel
                                  htmlFor="preferredLanguage"
                                  error={!!fieldState.error}
                                >
                                  {t("Preferred language")}
                                </InputLabel>
                                <Tooltip
                                  title={t(
                                    "Selecting a preferred language will change your default language in MyControlRisks. You can change this at any time in your account settings.",
                                  )}
                                  arrow
                                  placement="right"
                                  open={tooltipOpen}
                                  onClose={() => setTooltipOpen(false)}
                                >
                                  <IconButton
                                    onClick={() =>
                                      setTooltipOpen((prev) => !prev)
                                    }
                                    aria-label="info"
                                    disableRipple
                                    disableTouchRipple
                                    disableFocusRipple
                                    sx={{p: 0}}
                                  >
                                    <InfoIcon height={"14px"} width={"14px"} />
                                  </IconButton>
                                </Tooltip>
                              </Stack>

                              <Select
                                {...field}
                                IconComponent={ChevronDownIcon}
                                disabled={!canCompleteForm}
                                placeholder="Select the preferred language"
                                sx={{
                                  ".MuiSelect-icon": {
                                    right: "20px",
                                  },
                                  ".MuiInputBase-input": {
                                    paddingTop: "3px",
                                    paddingBottom: "4px",
                                    paddingLeft: "16px",
                                    borderRadius: "4px",
                                    transition: "all 0.2s ease-in-out",
                                  },
                                  ".MuiOutlinedInput-input": {
                                    borderStyle: "solid",
                                    borderWidth: "1px",
                                    borderColor: fieldState.error
                                      ? "#DB0D00"
                                      : "#BDBDBC",
                                  },
                                  ".Mui-disabled": {
                                    color: "#242d35",
                                    "-webkit-text-fill-color": "#242d35",
                                    opacity: 1,
                                    backgroundColor: "#BDBDBC",

                                    "::placeholder": {
                                      color: "#7A7A79",
                                      "-webkit-text-fill-color": "#7A7A79",
                                    },
                                  },
                                  fieldset: {
                                    border: "none",
                                  },
                                }}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  sx: {
                                    ".MuiPaper-root": {
                                      boxShadow:
                                        "0px 0px 2px 0px #0000001F, 0px 4px 8px 0px #00000024",
                                      border: "1px solid #EEEEEE",
                                      padding: "0",
                                      backgroundColor: "#FFFFFF",
                                    },
                                  },
                                  slotProps: {
                                    paper: {
                                      sx: {
                                        maxHeight: "160px",
                                      },
                                    },
                                  },
                                }}
                                displayEmpty
                                defaultValue={langs[0].value}
                                renderValue={(value) => {
                                  return (
                                    <Typography
                                      component="span"
                                      fontSize="14px"
                                      fontWeight={400}
                                      color="#242d35"
                                      lineHeight="1.43"
                                      textAlign="left"
                                      sx={{
                                        "-webkit-text-fill-color": "#242d35",
                                      }}
                                    >
                                      {
                                        langs.find(
                                          (item) => item.value === value,
                                        )?.label
                                      }
                                    </Typography>
                                  )
                                }}
                              >
                                {langs?.map((item) => (
                                  <MenuItem
                                    key={item.value}
                                    value={item.value}
                                    sx={{
                                      fontSize: "14px",
                                      padding: "8px 16px",
                                      cursor: "pointer",
                                      transition:
                                        "background-color 0.2s ease-in-out",
                                      backgroundColor:
                                        field.value === item.value
                                          ? "#DCEBF3"
                                          : "#FFFFFF",
                                      ":hover": {
                                        backgroundColor:
                                          field.value === item.value
                                            ? "#DCEBF3"
                                            : "#F5F5F5",
                                      },
                                    }}
                                    disableRipple
                                  >
                                    <Typography
                                      component="span"
                                      fontSize="14px"
                                      fontWeight={400}
                                      color="#242d35"
                                      lineHeight="1.43"
                                      textAlign="left"
                                    >
                                      {item.label}
                                    </Typography>
                                  </MenuItem>
                                ))}
                              </Select>
                              <ErrorMessage>
                                {fieldState.error
                                  ? fieldState.error.message
                                  : null}
                              </ErrorMessage>
                            </FormGroup>
                          )}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    component="footer"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="end"
                    gap="24px"
                    paddingTop="8px"
                    paddingX="32px"
                  >
                    <NewButton
                      type="button"
                      text={t("Cancel")}
                      onClick={handleClose}
                      disabled={completeOnboardingMutation.isPending}
                      variant="underline"
                      color="primary"
                      size="medium"
                      sx={{
                        paddingX: "0",
                        paddingBottom: "0",
                        borderRadius: "0",
                      }}
                    />
                    <NewButton
                      type={canCompleteForm ? "submit" : "button"}
                      text={t("Next")}
                      variant="filled"
                      color="primary"
                      size="medium"
                      onClick={
                        canCompleteForm ? undefined : handleSubmitInternal
                      }
                      disabled={completeOnboardingMutation.isPending}
                      sx={{
                        width: "136px",
                      }}
                    />
                  </Stack>
                </Stack>
              </TabPanel>
              <TabPanel value={step} index={1}>
                <Stack
                  width={"766px"}
                  component={"form"}
                  onSubmit={preferencesHandleSubmit(handleSubmitPreferences)}
                  paddingBottom="24px"
                  gap="16px"
                >
                  <Stack
                    gap={"16px"}
                    sx={{overflowY: "auto"}}
                    height={"487px"}
                    borderLeft={"1px solid #dedede"}
                    pt={"24px"}
                    px={"32px"}
                  >
                    <Typography
                      component="p"
                      fontSize="14px"
                      fontWeight={400}
                      color="#242d35"
                      lineHeight="1.43"
                      textAlign="left"
                    >
                      {t(
                        "You can complete your marketing preferences now or later in your account section.",
                      )}
                    </Typography>
                    <Typography
                      component="p"
                      fontSize="16px"
                      fontWeight={700}
                      color="#053747"
                      lineHeight="1.43"
                      textAlign="left"
                    >
                      {t("Areas of interest")}
                    </Typography>
                    <Grid container>
                      {aoiColumns.map((column, colIndex) => (
                        <Grid item xs={6} key={colIndex}>
                          <FormGroup>
                            {column.map((option) => (
                              <FormControlLabel
                                key={option.value}
                                control={
                                  <Controller
                                    name="selectedAreas"
                                    control={preferencesControl}
                                    render={({field}) => (
                                      <Checkbox
                                        disabled={
                                          decoded?.companyId ===
                                          CR_ACCOUNT_NUMBER
                                        }
                                        checked={
                                          Array.isArray(field.value) &&
                                          field.value.includes(option.value)
                                        }
                                        onChange={(e) => {
                                          const currentValues: string[] = (
                                            field.value || []
                                          ).filter(
                                            (val): val is string =>
                                              val !== undefined,
                                          )
                                          const newValue = e.target.checked
                                            ? [...currentValues, option.value]
                                            : currentValues.filter(
                                                (val) => val !== option.value,
                                              )
                                          field.onChange(newValue)
                                        }}
                                        style={{
                                          paddingTop: "8px",
                                          paddingBottom: "8px",
                                          paddingLeft: "8px",
                                          paddingRight: "8px",
                                        }}
                                      />
                                    )}
                                  />
                                }
                                label={t(option.label)}
                              />
                            ))}
                          </FormGroup>
                        </Grid>
                      ))}
                    </Grid>
                    <Typography
                      component="p"
                      fontSize="16px"
                      fontWeight={700}
                      color="#053747"
                      lineHeight="1.43"
                      textAlign="left"
                    >
                      {t("Geography of interest")}
                    </Typography>
                    <Grid container>
                      {goiColumns.map((column, colIndex) => (
                        <Grid item xs={6} key={colIndex}>
                          <FormGroup>
                            {column.map((option) => (
                              <FormControlLabel
                                key={option.value}
                                control={
                                  <Controller
                                    name="selectedGeographies"
                                    control={preferencesControl}
                                    render={({field}) => (
                                      <Checkbox
                                        disabled={
                                          decoded?.companyId ===
                                          CR_ACCOUNT_NUMBER
                                        }
                                        checked={
                                          Array.isArray(field.value) &&
                                          field.value.includes(option.value)
                                        }
                                        onChange={(e) => {
                                          const currentValues: string[] = (
                                            field.value || []
                                          ).filter(
                                            (val): val is string =>
                                              val !== undefined,
                                          )
                                          const newValue = e.target.checked
                                            ? [...currentValues, option.value]
                                            : currentValues.filter(
                                                (val) => val !== option.value,
                                              )
                                          field.onChange(newValue)
                                        }}
                                        style={{
                                          paddingTop: "8px",
                                          paddingBottom: "8px",
                                          paddingLeft: "8px",
                                          paddingRight: "8px",
                                        }}
                                      />
                                    )}
                                  />
                                }
                                label={t(option.label)}
                              />
                            ))}
                          </FormGroup>
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                  <Stack
                    component="footer"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="end"
                    gap="24px"
                    paddingTop="8px"
                    px={"32px"}
                  >
                    <ConfirmationDialog
                      showDialog={showSkipPreferencesDialog}
                      title={t("Skip marketing preferences")}
                      message={t(
                        "Your marketing preferences have not been saved. You can update these at any time in your profile.",
                      )}
                      handleClose={() => setShowSkipPreferencesDialog(false)}
                      handleConfirm={() => {
                        setOpen(false)
                        setPreferencesSkipped(true)
                      }}
                      closeText={t("Cancel")}
                      confirmText={t("Continue")}
                      closeIcon
                    />
                    <NewButton
                      type="button"
                      text={t("Skip")}
                      onClick={handleClosePreferences}
                      variant="underline"
                      color="primary"
                      size="medium"
                      sx={{
                        paddingX: "0",
                        paddingBottom: "0",
                        borderRadius: "0",
                      }}
                    />
                    <NewButton
                      type={canCompleteForm ? "submit" : "button"}
                      text={t("Confirm")}
                      variant="filled"
                      color="primary"
                      size="medium"
                      onClick={
                        canCompleteForm ? undefined : handleSubmitInternal
                      }
                      sx={{
                        width: "136px",
                      }}
                      disabled={decoded?.companyId === CR_ACCOUNT_NUMBER}
                    />
                  </Stack>
                </Stack>
              </TabPanel>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Modal>
  )
}

OnboardingModal.displayName = "OnboardingModal"
export default OnboardingModal

type CountrySelectProps = {
  value: string
  iconComponent: any
  onChange: (value: string) => void
  onBlur?: () => void
  onFocus?: () => void
  error?: boolean
  disabled?: boolean
  trigger: UseFormTrigger<FormValues>
}
const CountrySelect: FC<CountrySelectProps> = (props) => {
  const {
    value,
    iconComponent: Icon,
    onChange,
    onFocus,
    error,
    disabled,
    trigger,
  } = props
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [filteredCountries, setFilteredCountries] = useState(countries)

  const handleOpen = (event: MouseEvent<HTMLDivElement>) => {
    if (disabled) return
    setAnchorEl(event.currentTarget)
    onFocus?.()
  }

  const handleClose = () => {
    setAnchorEl(null)
    setFilteredCountries(countries)
    trigger("workPhoneNumber", {shouldFocus: true})
  }

  const handleSearchChange = (value: string) => {
    if (disabled) return
    if (value) {
      setFilteredCountries(
        countries.filter(
          (option) =>
            option.label.toLowerCase().startsWith(value.toLowerCase()) ||
            option.callingCode.includes(value),
        ),
      )
    } else {
      setFilteredCountries(countries)
    }
  }

  const handleCountrySelect = (country: string) => {
    if (disabled) return
    onChange(country)
    handleClose()
  }

  return (
    <Fragment>
      <Box width="auto" position="relative">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          paddingY="7px"
          paddingLeft="16px"
          paddingRight="8px"
          borderRadius="4px"
          gap="24px"
          height="32px"
          onClick={handleOpen}
          sx={{
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: error && !disabled ? "#DB0D00" : "#BDBDBC",
            backgroundColor: disabled ? "#BDBDBC" : "#FFFFFF",
            color: "#242d35",
            boxSizing: "border-box",
            userSelect: "none",
            cursor: disabled ? "default" : "pointer",
            transition: "border-color 0.2s ease-in-out",

            svg: {
              transform: anchorEl ? "rotate(180deg)" : "rotate(0deg)",
            },
          }}
        >
          <Stack direction="row" gap="8px" alignItems="center" fontSize="14px">
            {value ? <Icon label={value} aria-hidden country={value} /> : null}
            <Typography
              aria-label="country-code"
              component="span"
              fontSize="14px"
              fontWeight={400}
              lineHeight="1.43"
              color="#242d35"
            >
              {isSupportedCountry(value)
                ? `+${getCountryCallingCode(value)}`
                : null}
            </Typography>
          </Stack>
          <ChevronDownIcon />
        </Stack>
        <Box
          className="focus-indicator"
          sx={{
            position: "absolute",
            bottom: "0",
            left: "0",
            right: "0",
            height: "1.6px",
            width: "100%",
            opacity: Boolean(anchorEl) && !error ? 1 : 0,
            backgroundColor: "#7A7A79",
            borderBottomLeftRadius: "2px",
            borderBottomRightRadius: "2px",
            transition: "opacity 0.2s ease-in-out",
          }}
        />
      </Box>
      <Popover
        tabIndex={0}
        open={Boolean(anchorEl) && !disabled}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            boxShadow: "0px 0px 2px 0px #0000001F, 0px 4px 8px 0px #00000024",
            border: "1px solid #EEEEEE",
          },
        }}
        slotProps={{
          paper: {
            role: "listbox",
            "aria-modal": true,
            "aria-label": "country-select",
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Stack
            sx={{
              boxSizing: "border-box",
              backgroundColor: "#FFFFFF",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#DFDFDF",
              borderRadius: "0.25rem",
              boxShadow: "0px 0px 8px 0px #0000001F, 0px 4px 8px 0px #00000024",
              width: "312px",
              height: "184px",
            }}
          >
            <Stack
              height="44px"
              paddingX="16px"
              alignItems="center"
              justifyContent="center"
            >
              <SearchBar
                size="small"
                placeholder="Search country code or name"
                onChange={handleSearchChange}
                containerProps={{
                  sx: {
                    width: "100%",
                    maxWidth: "100%",
                  },
                }}
              />
            </Stack>
            <Stack height="140px" overflow="auto">
              {filteredCountries.length < 1 ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  paddingX="16px"
                  height="35px"
                  minHeight="35px"
                  gap="32px"
                >
                  <Typography
                    component="span"
                    fontSize="14px"
                    fontWeight={400}
                    lineHeight="1.43"
                    color="#242d35"
                  >
                    No results found
                  </Typography>
                </Stack>
              ) : (
                filteredCountries
                  .filter((option) => option.value)
                  .map((option, index) => (
                    <Stack
                      key={index}
                      aria-label={option.label}
                      role="option"
                      direction="row"
                      alignItems="center"
                      paddingX="16px"
                      height="35px"
                      minHeight="35px"
                      gap="32px"
                      onClick={() => handleCountrySelect(option.value)}
                      sx={{
                        backgroundColor:
                          value === option.value ? "#DCEBF3" : "#FFFFFF",
                        cursor: "pointer",
                        transition: "background-color 0.2s ease-in-out",
                        ":hover": {
                          backgroundColor:
                            value === option.value ? "#DCEBF3" : "#EEEEEE",
                        },
                        ":focus": {
                          backgroundColor:
                            value === option.value ? "#DCEBF3" : "#DEDEDE",
                        },
                      }}
                    >
                      <Stack
                        direction="row"
                        gap="8px"
                        alignItems="center"
                        fontSize="14px"
                        minWidth="61px"
                      >
                        <Icon
                          label={value}
                          aria-hidden
                          country={option.value}
                        />
                        <Typography
                          component="span"
                          fontSize="14px"
                          fontWeight={400}
                          lineHeight="1.43"
                          color="#242d35"
                        >
                          {isSupportedCountry(option.value)
                            ? `+${getCountryCallingCode(option.value)}`
                            : null}
                        </Typography>
                      </Stack>
                      <TextWithTooltip
                        text={option.label}
                        limit={20}
                        textStyle={{
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "1.43",
                          color: "#242d35",
                        }}
                        tooltipProps={{
                          arrow: true,
                          placement: "top",
                        }}
                      />
                    </Stack>
                  ))
              )}
            </Stack>
          </Stack>
        </ClickAwayListener>
      </Popover>
    </Fragment>
  )
}

const CustomInput = styled(InputBase)<InputBaseProps>(({theme, error}) => ({
  width: "100%",
  input: {
    padding: "8px 16px",
    fontSize: "14px",
    lineHeight: "20px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: error ? "#DB0D00" : "#BDBDBC",
    borderRadius: "4px",
    color: "#242d35",
    height: "32px",
    boxSizing: "border-box",
    transition: "border-color 0.2s ease-in-out",

    "::placeholder": {
      color: "#7A7A79",
    },

    "&:disabled": {
      backgroundColor: "#BDBDBC",
      borderColor: "#BDBDBC",
      color: "#242d35",
      "-webkit-text-fill-color": "#242d35",
    },
  },
}))

const ErrorMessage: FC<PropsWithChildren> = (props) => {
  const {children} = props
  return children ? (
    <Stack
      component="span"
      flexDirection="row"
      gap="4px"
      height="20px"
      alignItems="center"
    >
      <Fragment>
        <ReportProblemIcon />
        <Typography
          aria-label="error-message"
          component="span"
          fontSize="14px"
          fontWeight={400}
          lineHeight="20px"
          color="#DB0D00"
        >
          {children}
        </Typography>
      </Fragment>
    </Stack>
  ) : null
}

type InputLabelProps = {
  error?: boolean
  required?: boolean
  htmlFor?: string
}
const InputLabel: FC<PropsWithChildren<InputLabelProps>> = (props) => {
  const {children, error, required, htmlFor} = props
  return (
    <Stack
      component="label"
      htmlFor={htmlFor}
      flexDirection="row"
      gap="4px"
      height="24px"
      alignItems={"center"}
    >
      <Typography
        component="span"
        fontSize="14px"
        fontWeight={400}
        lineHeight={1.43}
        color={error ? "#DB0D00" : "#053747"}
        sx={{
          transition: "color 0.2s ease-in-out",
        }}
      >
        {children}
      </Typography>
      {required ? (
        <Typography
          component="span"
          fontSize="16px"
          fontWeight={400}
          lineHeight="20px"
          color="#DB0D00"
        >
          *
        </Typography>
      ) : null}
    </Stack>
  )
}

const FormGroup = styled("article")(({theme}) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  flexWrap: "nowrap",
  flex: 1,
}))

const Input: FC<InputBaseProps> = (props) => {
  return (
    <Box
      width="100%"
      position="relative"
      sx={{
        "&:focus-within": {
          ".focus-indicator": {
            opacity: props.error ? 0 : 1,
          },
        },
      }}
    >
      <CustomInput {...props} />
      <Box
        className="focus-indicator"
        sx={{
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0",
          height: "1.6px",
          width: "100%",
          opacity: 0,
          backgroundColor: "#7A7A79",
          borderBottomLeftRadius: "2px",
          borderBottomRightRadius: "2px",
          transition: "opacity 0.2s ease-in-out",
        }}
      />
    </Box>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

function TabsProps(index: number, selectedValue: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
    sx: {
      color: index === selectedValue ? "#226ca5" : "#595958",
      backgroundColor: index === selectedValue ? "#eeeeee" : "inherit",
      fontSize: "16px",
      textTransform: "none",
      fontWeight: index === selectedValue ? 600 : 400,
      alignItems: "start",
      paddingLeft: index === selectedValue ? "16px" : "20px",
      cursor: "default",
    },
  }
}

const splitIntoVerticalColumns = (
  items: {label: string; value: string}[],
  columns: number,
) => {
  const sortedItems = [...items].sort((a, b) => a.label.localeCompare(b.label))
  const columnLength = Math.ceil(sortedItems.length / columns)

  return Array.from({length: columns}, (_, i) =>
    sortedItems.slice(i * columnLength, (i + 1) * columnLength),
  )
}
